<script lang="ts" setup>
import type { PropType } from 'vue'
import { EllipsisHorizontalIcon, CheckCircleIcon, PauseIcon } from '@heroicons/vue/20/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useToast } from 'vue-toastification'
import { useQueryClient } from '@tanstack/vue-query'
import useModal from '@/modules/shared/composable/useModal'
import MODALS from '@/modules/shared/utils/modals_enum'
import ButtonR from '@/modules/shared/components/ButtonRemove.vue'
import { setDates } from '@/modules/settings/utils/helpers/dates'
import type { IAlerts } from '@/modules/explore/interfaces/Alerts'
import useTable from '@/modules/settings/composables/useTable'
import useAlerts from '@/modules/explore/composables/useAlerts'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const toast = useToast()
const queryClient = useQueryClient()
const { editAlerts } = useAlerts()

const { selectedItems, selectItem } = useTable()
const { showModalUpdateAlert, removeModal } = useModal()
const { removeData } = useAlerts()

defineProps({
  alerts: {
    type: Object as PropType<IAlerts[]>,
    required: true
  }
})

const pauseAlert = async (id:string, status:boolean) => {
  editAlerts.mutate({ alert: { is_active: !status }, id }, {
    onSuccess: () => {
      const query = queryClient.getQueryCache().find(['alerts'], { exact: true })
      query?.fetch()
      toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
    }
  })
}
</script>

<template>
  <tbody class="divide-y divide-gray-200">
    <tr v-for="alert in alerts" :key="alert.id" :class="[selectedItems.includes(alert.id) && 'bg-gray-50']" class="h-18">
      <td class="relative px-7 sm:w-12 sm:px-6">
        <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="selectedItems.includes(alert.id)"
          @change="selectItem($event, alert.id)" />
      </td>
      <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
        {{ alert.name }}
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ alert.created_by }}</td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ setDates(alert.created) }}</td>
      <td class="whitespace-nowrap px-3 py-4 text-sm">
        <span v-if="alert.is_active" class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
          <CheckCircleIcon class="h-4 w-4 text-green-00" aria-hidden="true" />
          Active
        </span>
        <span v-else class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
          <PauseIcon class="h-4 w-4 text-green-00" aria-hidden="true" />
          Paused
        </span>
      </td>
      <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 text-center">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="flex items-center rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
              <span class="sr-only">Open options</span>
              <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="py-1 absolute right-0 z-10 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <MenuItem>
                <ButtonR
                  type-color="gray"
                  text="Edit alert"
                  @click="showModalUpdateAlert({
                    name: MODALS.updateAlerts, data: {
                      id: alert.id,
                    }
                  })" />
                </MenuItem>
                <MenuItem>
                  <ButtonR
                    v-if="alert.is_active"
                    type-color="gray"
                    @click="pauseAlert(alert.id, alert.is_active)"
                    text="Pause alert" />
                  <ButtonR v-else type-color="gray" text="Resume alert" @click="pauseAlert(alert.id, alert.is_active)" />
                </MenuItem>
                <MenuItem>
                  <ButtonR type-color="red" text="Delete alert" @click="removeModal(removeData([alert.id]))" />
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<style scoped></style>
