<template>
    <div class="ring-inset ring-1 ring-gray-950/20 bg-gray-200 flex justify-center items-center text-gray-500" :class="setClassAvatar"><span class="h-5 w-5 text-center">{{ initials }}</span></div>
  </template>
  
  <script setup>
  import { computed } from 'vue'
  
  const props = defineProps({
    type: {
      type: String,
      default: 'initials'
    },
    size: {
      type: String,
      default: 'md'
    },
    avatarName: {
      type: String,
      default: ''
    }
  })
  
  const initials = computed(() => props.avatarName.slice(0, 1).toUpperCase())
  
  const setClassAvatar = computed(() => {
    let style = ''
    let size = ''
    if (props.size === 'sm') size = 'h-6 w-6'
    else if (props.size === 'lg') size = 'h-10 w-10'
    else size = 'h-8 w-8'
    if (props.isCircle) style = 'rounded-full'
    else {
      if (style === 'sm') style = 'rounded'
      else if (style === 'lg') style = 'rounded-lg'
      else style = 'rounded-md'
    }
    if (!props.picture && props.type !== 'initials') {
      if (props.size === 'sm') size = size + ' p-1'
      else if (props.size === 'lg') size = size + ' p-2'
      else size = size + ' p-[6px]'
    } else if (!props.picture && props.type === 'initials') {
      if (props.size === 'sm') size = size + ' text-xs p-1 leading-4'
      else if (props.size === 'lg') size = size + ' text-lg p-2 leading-6'
      else size = size + ' text-sm p-[6px] leading-5'
    }
    return `${style} ${size}`
  })
  </script>
  