<script setup>
import { onBeforeMount, reactive, ref, computed, watch, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import ModalComponent from '@/modules/shared/components/ModalComponent.vue'
import PanelLayout from '@/modules/shared/pages/PanelLayout.vue'
import { useMainStore } from '@/modules/shared/stores/mainStore.ts'
import useModal from '@/modules/shared/composable/useModal'
import {
  HomeIcon, Cog8ToothIcon, QuestionMarkCircleIcon, BellIcon
} from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import useDemoApi from '@/modules/shared/composable/useDemoApi'

const router = useRouter()
const route = useRoute()

const sideBar = reactive({
  headerItem: [],
  footerItem: []
})

const isOpen = ref(false)
const handleOpen = computed(() => isOpen.value)
const url = new URL(window.location.href)
const user = ref()
const mainStore = useMainStore()
const { showModal } = useModal()
const { getMe } = useDemoApi()


user.value = getMe()

watch(
  () => route.path,
  (newPath) => {
    if (newPath) isOpen.value = false
  }
)

onBeforeMount(() => {
  if (url.pathname !== '/login') {
    const savedToken = localStorage.getItem('userToken') || ''
    const urlToken = url.searchParams.get('token') || ''

    if (savedToken.length > 0 || urlToken.length > 0) {
      localStorage.setItem('userToken', urlToken.length > 0 ? urlToken : savedToken)
      if (urlToken !== '') router.push({ name: 'dashboard' })
    } else {
      localStorage.setItem('errorLogin', url.searchParams.get('error') || '')
      router.push({ name: 'login' })
    }
  }
  updateWindowWidth()
  window.addEventListener('resize', updateWindowWidth)
})

const updateWindowWidth = () => {
  const windowWidth = window.innerWidth || document.body.clientWidth
  mainStore.setWindowWidth(windowWidth)
}

const removeListener = () => {
  window.removeEventListener('resize', updateWindowWidth)
}

onBeforeUnmount(() => removeListener())

sideBar.headerItem = [
  {
    typeComponent: 'router-link',
    icon: HomeIcon,
    text: 'Dashboard',
    to: '/dashboard'
  },
  {
    typeComponent: 'router-link',
    icon: BellIcon,
    text: 'Alerts',
    to: '/alerts/'
  },
  {
    icon: Cog8ToothIcon,
    typeComponent: 'router-link',
    text: 'Settings',
    to: '/settings/mailboxes'
  }
]
sideBar.footerItem = [
  {
    icon: QuestionMarkCircleIcon,
    typeComponent: 'button',
    text: 'Help',
    handlerClick: () => window.open('https://docs.enterprise.emailmeter.com/', '_blank')
  }
]
</script>

<template>
  <ModalComponent />
  <RouterView v-if="route.fullPath === '/login'"/>
  <div v-else class="h-dvh w-screen !bg-gray-50" :key="isOpen">
    <PanelLayout
      class="h-full bg-gray-100"
      :close="handleOpen"
      :user="user"
      :header-items="sideBar.headerItem"
      :footer-items="sideBar.footerItem"
      @is-open="isOpen = true"
    />
    <div v-if="mainStore.isMobileOrTablet && isOpen" class="bg-gray-950/70 absolute top-0 bottom-0 left-[326px] right-0">
      <div class="h-10 w-10 p-2 fixed right-2 top-3 text-white cursor-pointer  rounded-lg hover:bg-white/10" @click="isOpen = false">
        <XMarkIcon class="h-6 w-6" />
      </div>
    </div>
    <div
      class="bg-gray-50 flex flex-col absolute right-0 justify-center flex-1 px-8 pt-8"
      :class="[mainStore.isMobileOrTablet ? 'left-0 top-16 bottom-0' : 'top-11 bottom-0 h-screen left-[264px] rounded-tl-xl border-l-1 overflow-y-scroll mt-[10px] border border-t-1 border-gray-200', isOpen ? 'hidden' : '']"
    >
      <RouterView class="flex flex-col flex-1 mx-auto" />
    </div>
  </div>
</template>
