<script lang="ts" setup>
</script>

<template>
  <div :toast-id="Math.random()">
    <h1 class="text-emerald-800 font-medium text-sm pb-1 font-inter">You are on a demo environment</h1>
    <span class="text-emerald-700 font-normal text-sm leading-5 font-inter">All changes made to existing settings <br> won’t be reflected in the product.</span>
  </div>
</template>

<style>
.Vue-Toastification__toast{
  align-items: flex-start !important;
}

.Vue-Toastification__icon {
  margin: inherit !important;
  margin: 0px 10px 0px 0px !important;
}
</style>
