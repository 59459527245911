<script setup lang="ts">
import { ref } from 'vue'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

import useModal from '@/modules/shared/composable/useModal'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useUsers from '@/modules/settings/composables/useUsers'
import useTeams from '@/modules/settings/composables/useTeams'
import useAlerts from '@/modules/explore/composables/useAlerts'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const { deleteMailboxes } = useMailboxes()
const { deleteUsers } = useUsers()
const { deleteTeams } = useTeams()
const { deleteAlerts } = useAlerts()
const { deleteData, hideModal } = useModal()
const queryClient = useQueryClient()
const toast = useToast()

const isDisabled = ref<boolean>(false)

const deleteFromBq = ref(false)

const remove = () => {
  if (deleteData.value.type === 'mailboxes') {
    deleteMailboxes.mutate(
      { data: { emails: deleteData.value.data, delete_from_bq: deleteFromBq.value } }, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['mailboxes'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      })
    isDisabled.value = deleteMailboxes.isLoading.value || deleteMailboxes.isSuccess.value
  }
  if (deleteData.value.type === 'users') {
    deleteUsers.mutate(
      { data: { emails: deleteData.value.data } }, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['users'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      })
    isDisabled.value = deleteUsers.isLoading.value || deleteUsers.isSuccess.value
  }
  if (deleteData.value.type === 'teams') {
    deleteTeams.mutate(
      { data: { teams: deleteData.value.data } }, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['teams'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      })
    isDisabled.value = deleteTeams.isLoading.value || deleteTeams.isSuccess.value
  }
  if (deleteData.value.type === 'alerts') {
    deleteAlerts.mutate(
      { data: { alerts: deleteData.value.data } }, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['alerts'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      })
    isDisabled.value = deleteMailboxes.isLoading.value || deleteMailboxes.isSuccess.value
  }
}
</script>

<template>
  <div class="p-6 pb-0 overflow-auto">
    <div class="bg-red-100 w-12 h-12 flex items-center justify-center rounded-full ">
      <TrashIcon class="w-6 h-6 text-red-500" />
    </div>

    <p class="text-gray-900 font-semibold leading-8 text-lg mt-5">{{ deleteData.title }}</p>
    <p class="text-sm leading-5 font-normal text-gray-500 pt-2">{{ deleteData.text }}</p>

    <div v-if="deleteData.type === 'mailboxes'" class="pt-5">
      <fieldset>
        <div class="relative flex items-start">
          <div class="flex h-6 items-center">
            <input id="remove" aria-describedby="remove-description" v-model="deleteFromBq" name="remove" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0" />
            <p class="ml-2 text-sm leading-5 font-medium text-gray-700">Delete all their existing email data from the dashboard</p>
          </div>
        </div>
      </fieldset>
    </div>
  </div>

  <ButtonsModal
    type="Remove"
    color="red"
    :isDisabled="isDisabled"
    @submit="remove" />
</template>

<style scoped></style>
