import type { IDashboard } from '@/modules/dashboard/interfaces/dashboard'

const useDemoApi = () => {
  const getMe =  () => {
    return {
      allowedDomains: ['dundermifflin.com'],
      maxMailboxes: 25,
      maxUsers: 25,
      maxAlerts: 5,
      email: 'michael.scott@dundermifflin.com',
      isAdmin: 'admin',
      name: 'Michael Scott',
      roles: 'admin'
    }
  }

  const getDashboard = async ():Promise<IDashboard[]> => {
    // return []
    return [{
      name: 'Email Meter',
      created: '2023-06-02',
      dashboard_url: 'https://lookerstudio.google.com/reporting/79b1e8e7-6881-4cb9-8ba4-49bbf29460d7',
      dashboard_embedded_url: 'https://lookerstudio.google.com/embed/reporting/79b1e8e7-6881-4cb9-8ba4-49bbf29460d7/'
    }]
  }

  return {
    getMe,
    getDashboard
  }
}

export default useDemoApi
