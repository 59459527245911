<script lang="ts" setup>
import { ref } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'

import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import type { IModalData, IModalData2 } from '@/modules/shared/interfaces/Modal'
import { domainReg, subdomainReg, emailReg } from '@/modules/shared/utils/reg'
import InputMultiple from '@/modules/shared/components/InputMultiple.vue'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useModal from '@/modules/shared/composable/useModal'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import ToastDemoComponent from '@/modules/shared/components/ToastDemoComponent.vue'

const queryClient = useQueryClient()
const toast = useToast()
const { modalData, hideModal } = useModal()
const { AddContactGroup, getContacts } = useContactGroups()

const item = ref('')
const items = ref<Iitems[]>([])

const groupName = (modalData.value as IModalData2).name ?? ''

const isDisabledText = () => (items.value.filter((e) => e.status === false).length > 0)

const isDisabled = () => (items.value.length === 0 || isDisabledText())
const checkRepeatedMailboxes = ():string[] => ((modalData.value as IModalData).mailboxes.map(data => data.item))

const ConfirmAddContacts = () => {
  const is = items.value.map((mailbox) => mailbox.item)
  AddContactGroup.mutate({ name: (modalData.value as IModalData2).name, mailboxes: is.filter((item, index) => is.indexOf(item) === index) }, {
    onSuccess: () => {
      getContacts.mutate((modalData.value as IModalData2).name, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success(ToastDemoComponent, { icon: CheckCircleIcon })
        }
      })
    }
  })
}
</script>

<template>
  <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">Add contacts</p>
  <div class="p-6 border-b overflow-auto">
    <label for="contact" class="block mb-6 text-sm font-normal leading-5 text-gray-700">Enter the list of mailboxes/domains you want to add to “{{groupName}}”.</label>
    <label for="contact" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Email addresses or domains</label>

    <InputMultiple
      placeholder="Type in or paste a list of mailboxes and/or domains"
      maxHeight="h-32"
      v-model:text="item"
      v-model:items="items"

      :reg="(e:string) => (!(domainReg.test(e) || subdomainReg.test(e) || emailReg.test(e)))"
      :allowedDomains="[]"
      :onlyExistEmails="checkRepeatedMailboxes()"
    />
    <span v-show="isDisabledText()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
      Please check that all email addresses are correct.
    </span>
  </div>
  <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddContacts" />
</template>

<style scope></style>
