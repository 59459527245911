<script setup lang="ts">
import { ref } from 'vue'
import logoUrl from '@/assets/images/logo.svg'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

const password = ref('')
const errorPassword = ref(false)

const login = () => {
  if (password.value === 'd3modashboard') window.location.href = `${import.meta.env.VITE_FRONTEND_HOST}?token=123`
  else errorPassword.value = true
}
</script>

<template>
  <div class="flex flex-col h-screen justify-between login-bg bg-gray-100 w-full">
    <div class="mx-auto mb-6 mt-10">
      <img :src="logoUrl" width="200" />
    </div>
    <div class="flex justify-center">
      <div class="w-94">
        <p class="text-3xl font-semibold leading-8 text-gray-900 text-center mb-3 mt-0">Take a product tour</p>
        <p class="font-normal leading-6 text-center text-gray-600 mb-8">See what it’s like to use Email Meter Enterprise <br> with a live demo through the eyes of an admin.</p>

        <div class="relative mt-2 rounded-md shadow-sm mb-2">
          <input
            class="block w-full rounded-md border-solid border-gray-300 shadow-xs"
            :class="{'border-red-500 addBusinesstext-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500': errorPassword}"
            type="password"
            placeholder="Enter password"
            v-model="password"
            @keyup.enter="login"
          >
          <div v-if="errorPassword" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        </div>

        <span v-if="errorPassword" class="text-red-500 text-sm">The password is not correct, please try again.</span>
        <ButtonG
          :inactive="password.length === 0"
          text="Log in"
          class="mt-6"
          @onClick="login"
        />
      </div>
    </div>

    <div class="md:flex items-center justify-between px-8 py-6">
      <p class="text-gray-500 font-inter font-normal text-base leading-6 m-0">
        Don't have a password?
        <a
          class="text-gray-500 hover:text-indigo-500 font-semibold ml-2 cursor-pointer no-underline"
          href="mailto:hello@emailmeter.com"
        >
          Get in touch
        </a>
      </p>
      <div class="flex text-base flex-wrap font-inter justify-center m-0">

        <a
          class=" text-gray-500 hover:text-indigo-500 leading-6 font-semibold  no-underline"
          href="https://www.emailmeter.com/privacy-policy"
          target="_blank"
        >
          Privacy
        </a>
        <a
          class="ml-4 text-gray-500 hover:text-indigo-500 leading-6 font-semibold no-underline"
          href="https://www.emailmeter.com/terms-of-service"
          target="_blank"
        >
          Terms
        </a>
        <a class="ml-4 text-gray-500 hover:text-indigo-500 leading-6 font-semibold no-underline" href="https://help.emailmeter.com/en/" target="_blank">
          Get help
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.login-bg {
  background-image: url(@/assets/images/background-down.svg), url(@/assets/images/background-up.svg);
  background-position: 0 100%, 100% 0;
  background-size: 20%, 25%;
  background-repeat: no-repeat, no-repeat;
}
</style>
