<script setup>
import BaseItem from '@/modules/shared/components/BaseItem.vue'
import AccountItem from '@/modules/shared/components/AccountItem.vue'
import BannerContent from '@/modules/shared/components/BannerContent.vue'

import { useMainStore } from '@/modules/shared/stores/mainStore.ts'
import { ref, h, onMounted } from 'vue'
import { Bars3Icon } from '@heroicons/vue/24/solid'
import imgUrl from '@/assets/images/logo.svg'

const props = defineProps({
  close: {
    type: Boolean,
    required: true
  },
  user: {
    type: Object,
    required: true
  },
  headerItems: {
    type: Array,
    default: () => []
  },
  footerItems: {
    type: Array,
    default: () => []
  }
})
const mainStore = useMainStore()
const emit = defineEmits(['is-open'])
const listItemHeader = ref([])
const listItemFooter = ref([])
const isOpen = ref(props.close)
const arrayCopyHeader = ref([])
const arrayCopyFooter = ref([])

onMounted(() => {
  setSideBar()
})

const setSideBar = () => {
  for (let a = 0; a < props.headerItems.length; a++) {
    const obj = props.headerItems[a]
    const item = h(BaseItem, {
      typeComponent: obj?.typeComponent,
      icon: obj?.icon,
      text: obj?.text,
      to: obj?.to,
      handlerClick: obj?.handlerClick
    })
    listItemHeader.value.push(item)
  }
  arrayCopyHeader.value = Array.from(listItemHeader.value)

  for (let a = 0; a < props.footerItems.length; a++) {
    const obj = props.footerItems[a]
    const item = h(BaseItem, {
      typeComponent: obj?.typeComponent,
      icon: obj?.icon,
      text: obj.text,
      to: obj?.to,
      handlerClick: obj?.handlerClick
    })
    listItemFooter.value.push(item)
  }
  arrayCopyFooter.value = Array.from(listItemFooter.value)
}
</script>

<template>
  <div>
      <BannerContent v-if="!mainStore.isMobileOrTablet" />
      <aside class="flex" :class="mainStore.isMobileOrTablet && !isOpen ? 'h-16 w-full' : mainStore.isMobileOrTablet ? 'h-full w-[326px]' : 'h-[96%] w-[264px]'">
          <nav class="flex" :class="mainStore.isMobileOrTablet && !isOpen ? 'h-full w-full justify-between px-4 py-3' : 'flex-col w-full'">
              <router-link :class="mainStore.isMobileOrTablet && !isOpen ? 'mt-1' : 'pr-4 pl-5 py-6'" :to="{ name: 'dashboard' }">
                  <img :src="imgUrl" class="pl-2 h-[30px] w-[152px]" alt="Email Meter" />
              </router-link>

              <button
                  v-if="mainStore.isMobileOrTablet && !isOpen"
                  class="cursor-pointer p-2 rounded-lg hover:bg-gray-200"
                  @click="
                      isOpen = true;
                      emit('is-open');
                  "
              >
                  <Bars3Icon class="h-6 w-6" />
              </button>

              <ul v-else class="flex flex-col justify-between flex-1">
                  <header class="flex flex-col gap-0.5">
                      <li v-for="(item, index) in arrayCopyHeader" :key="index" class="relative rounded-md h-9 mx-3">
                          <component :is="item" class="absolute w-full" />
                      </li>
                  </header>

                  <footer class="flex flex-col pb-6 gap-0.5">
                      <li v-for="(item, index) in arrayCopyFooter" :key="index" class="relative rounded-md h-9 mx-3">
                          <component :is="item" class="absolute w-full" />
                      </li>
                  </footer>
              </ul>

              <AccountItem v-if="!mainStore.isMobileOrTablet || isOpen" :hint-text="user?.email" />
          </nav>
      </aside>
  </div>
</template>
