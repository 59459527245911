/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import type { ContactGroups, ContactGroups2 } from '@/modules/settings/interfaces/ContactGroups'
import { useMutation, useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'

import { useContactGroupsStore } from '@/modules/settings/stores/contactGroups'
import { watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

const dataInitial = {
  contact_groups: [{
    contact_group: 'Prospects',
    contacts_number: 21,
    items: []
  }, {
    contact_group: 'Tier 1 Clients',
    contacts_number: 19,
    items: []
  }, {
    contact_group: 'Tier 2 Clients',
    contacts_number: 18,
    items: []
  }, {
    contact_group: 'Tier 3 Clients',
    contacts_number: 8,
    items: []
  }]
}

const dataInitial2:ContactGroups2 = {
  contact_groups: [{
    contact_group: 'Prospects',
    contact_values: [{ id: '742b1c74d3274619bd00d15070c36da8', domain: 'ajira-airways.com', address: '' }, { id: '9e5ed6cf8b9d4ea4b30014ed1e432245', domain: 'amaya.com', address: '' }, { id: 'f3332ff776aa48699a44eac730c7deda', domain: 'athlead.com', address: '' }, { id: 'b63a46bfa5ca4f468856d2cf23aa56bc', domain: 'breadstix.com', address: '' }, { id: 'c7e0442cf3de4a358b489b7643250a74', domain: 'butte-airlines.com', address: '' }, { id: '6d9142f3e8d54ba4b4348f63130343dc', domain: 'championship-vinyl.com', address: '' }, { id: 'b781a2ed605d4d169338bc400af53238', domain: 'cloudasta.com', address: '' }, { id: 'f722448f564441d69bbb5bd3e3925cbf', domain: 'gregariousgames.com', address: '' }, { id: '5034a3ae63124455ae95975eb8ef5c2e', domain: 'kaiba-corporation.jp', address: '' }, { id: '7de1c6fe6e5b44238fcd060831a14c14', domain: 'lacuna-inc.com', address: '' }, { id: '6d721255a6de41c0b5182848c346ad8a', domain: 'matsumara-fishworks.jp', address: '' }, { id: 'd32eafa7a3194acb81838bc73bc3506e', domain: 'mojodojocasahouse.com', address: '' }, { id: 'f3e4892475dd40d19457003ad4fda6dd', domain: 'monsters-inc.com', address: '' }, { id: '3200c586c463437eb2d0e3932a36b7c8', domain: 'oceanic-airlines.com', address: '' }, { id: 'b2c0c542a9d84116884110b41c48a373', domain: 'piedpiper.com', address: '' }, { id: 'df89402020e54c119a062915edb36971', domain: 'quickstopgroceries.com', address: '' }, { id: '2e7579761c3e4a8992fefcf693e435c6', domain: 'shinra-electric-power-company.jp', address: '' }, { id: 'c42d5663665347339e8dbb91d9d82282', domain: 'shuttlecloud.com', address: '' }, { id: '5146d0e77f49490a9c9d7e27b65b8b96', domain: 'vapid-motors.com', address: '' }, { id: '03797fcb119e4335934f99cd13b7fbf7', domain: 'wariowareinc.com', address: '' }, { id: '46d2a175b4b94d2cb8fa6ae32f02f4e0', domain: 'weasleyswizardwheezes.com', address: '' }]
  }, {
    contact_group: 'Tier 1 Clients',
    contact_values: [{ id: '8b4724f99c204a63a7685cce9334f2f9', domain: 'aperture-science.net', address: '' }, { id: 'cca77dbff9fb4e8ca3a54e8b2d5c1910', domain: 'black-mesa.com', address: '' }, { id: 'cf62f778ccd241daad1b53ca9d498dba', domain: 'cyberdynesystems.net', address: '' }, { id: '699d38400212438fa18fd20a89dafedf', domain: 'encom.com', address: '' }, { id: '24e13b96caf64c5fb5b2ba72a8bb0f04', domain: 'gadgetron-corporation.com', address: '' }, { id: '133975426eba43b69eca50cf42680eb2', domain: 'graymattertechnologies.com', address: '' }, { id: '22283639a47f4fd78fa6f8f92f98bf33', domain: 'hooli.com', address: '' }, { id: 'faf0d69e217b4bb883001ccdf655bf32', domain: 'ingen.com', address: '' }, { id: '51c99bc72a534da2867ef5f3cea7d2a9', domain: 'initech.net', address: '' }, { id: '71db6b5b5b994910ab52c8b0eef17093', domain: 'lunarindustries.com', address: '' }, { id: 'cd0e1adfedfd4b5392aa286e8515b5d3', domain: 'massive-dynamic.com', address: '' }, { id: 'c78cad88dbae48ebb3004e3681bc5aa0', domain: 'oscorp.com', address: '' }, { id: '409b72faed0e4f209c65d6b7611bf61c', domain: 'siriuscybernetics.com', address: '' }, { id: '02e52f067c814a7e9304b1fdd7bbec54', domain: 'spacelyspacesprockets.com', address: '' }, { id: '1bcec150fb894611892f3fe965a02219', domain: 'starkindustries.com', address: '' }, { id: 'e81c90d3dc014d72a8d330f451d4c65f', domain: 'tyrell.com', address: '' }, { id: '0bce0eba74984a288e66ff5dee3c617f', domain: 'umbrella.com', address: '' }, { id: '658be4c11b9547c38e072a18bdc736b8', domain: 'wayneindustries.com', address: '' }, { id: 'bb72626aa9724bb492b5fb986191b5f5', domain: 'weyland-yutani.com', address: '' }]
  }, {
    contact_group: 'Tier 2 Clients',
    contact_values: [{ id: '284d8c33f14f4539b34202319ab318c5', domain: 'acmecorp.com', address: '' }, { id: 'b55694884efe4639b16583b9a0df20a8', domain: 'bluthcompany.com', address: '' }, { id: 'd98435132b2f42f78144c9b8e99e6d91', domain: 'choam.com', address: '' }, { id: 'd54e2d412c314b4c8dff1c1a370d391c', domain: 'compuglobalhypermega.net', address: '' }, { id: 'fa089aed7917462cb057336ff037ca24', domain: 'e-corp.com', address: '' }, { id: '8291f6e5eb794fa681c7dcff5577e280', domain: 'emailmeter.com', address: '' }, { id: 'b6f9907c00ec452d83d8c60873086abc', domain: 'globex.com', address: '' }, { id: 'bd9627a510d340df827e0fabcc3dadd7', domain: 'kaiba-corporation.jp', address: '' }, { id: 'e06f38ac1fee4c1c818528d32f9d704e', domain: 'lexcorp.com', address: '' }, { id: 'b9d7135375b345959735e9814212bcb3', domain: 'momcorp.com', address: '' }, { id: '4fb7d450b64e4c86af7ef574baf7df58', domain: 'nakatomitrading.com', address: '' }, { id: 'b4500884a52945f089f218d2984329a8', domain: 'paperstreetsoap.com', address: '' }, { id: '5cd6f5b357f54b40a697f9b7f863b29f', domain: 'pendant-publishing.com', address: '' }, { id: '2f76c6564e094f54a9c6cc35c24706bf', domain: 'prestigeworldwide.com', address: '' }, { id: 'dcea35bb195843e39706bcbb0c7d24f4', domain: 'sterling-cooper.com', address: '' }, { id: '671a62809cc7494ea6ca9fde701e97d7', domain: 'vandelay-industries.com', address: '' }, { id: 'f54f7f8b65744722905802f88deae24a', domain: 'vapid-motors.com', address: '' }, { id: '5cefe1db21fc40b7b72b9d20762013b7', domain: 'verybigcorpofamerica.com', address: '' }]
  }, {
    contact_group: 'Tier 3 Clients',
    contact_values: [{ id: '6ce0b6bad3f04f8a9434663273978e1b', domain: 'bigkahunaburger.com', address: '' }, { id: '7abf6b8d0bc94ed2b66841f648ff93f2', domain: 'bubba-gump.com', address: '' }, { id: '97b89dbcbf0741d5a6ab489713a4cc7c', domain: 'buzz-cola.com', address: '' }, { id: '5aaf85cd2010439e9bcce37a6f972b72', domain: 'duff.com', address: '' }, { id: '61c6c64ed12740cf9009c2e6f2d58f3f', domain: 'fawlty-towers.co.uk', address: '' }, { id: '1db598f183c24a2bae494b198e64d814', domain: 'krusty-krub.com', address: '' }, { id: 'a0d56bc18e3a4ace91c4c5b659e57b0c', domain: 'lospolloshermanos.com', address: '' }, { id: '7b7efadb2cf6409691c66857f5335016', domain: 'wonka.com', address: '' }]
  }]
}

const useContactGroups = () => {
  const storeContactGroups = useContactGroupsStore()
  const { contactGroups, contactGroups2, contacts } = storeToRefs(storeContactGroups)

  const handleOrderGroup = (data: ContactGroups[]) => {
    data.sort(function (a, b) {
      if (a.contact_group > b.contact_group) return 1
      if (a.contact_group < b.contact_group) return -1
      return 0
    })
  }

  const getContactGroupsMutation = useQuery(['contactGroups'], async ():Promise<ContactGroups[]> => {
    if (contactGroups.value.length === 0) {
      storeContactGroups.setContactGroups(dataInitial.contact_groups)
      storeContactGroups.setContactGroups2(dataInitial2)
    } else {
      handleOrderGroup(contactGroups.value)
      storeContactGroups.setContactGroups(contactGroups.value)
    }
    return await new Promise<ContactGroups[]>((resolve) => {
      resolve(contactGroups.value)
    })
  })

  watch(getContactGroupsMutation.data, cg => {
    if (cg) storeContactGroups.setContactGroups(cg)
  }, { immediate: true })

  const getContactsMutation = useMutation(async (name:string) => {
    const data = contactGroups2.value!.contact_groups.find((di) => di.contact_group === name)

    const a = data!.contact_values.map((a) => {
      if (a.address.length && a.address !== null) return { id: a.id, item: a.address }
      else return { id: a.id, item: a.domain }
    })
    storeContactGroups.setContact(a)
    return contacts.value
  })

  const AddContactGroupMutation = useMutation(async (data: { name: string; mailboxes: string[]; }):Promise<void> => {
    const m = data.mailboxes.map((m) => ({ id: uuidv4(), item: m }))
    const mm = m.map(mm => ({
      id: mm.id,
      domain: mm.item,
      address: ''
    }))

    const contactGroup = contactGroups.value.find((cg) => cg.contact_group === data.name)
    const newContactGroups = contactGroups.value.filter((cg) => cg.contact_group !== data.name)
    const newContactGroup2 = contactGroups2.value!.contact_groups.filter((cg) => cg.contact_group !== data.name)

    if (contactGroup === undefined) {
      storeContactGroups.setContactGroups2({
        contact_groups: [
          ...newContactGroup2, {
            contact_group: data.name,
            contact_values: [...mm]
          }
        ]
      })

      storeContactGroups.setContactGroups([
        ...newContactGroups, {
          contact_group: data.name,
          contacts_number: data.mailboxes.length,
          items: []
        }])

      storeContactGroups.setContact([...contacts.value, ...m])
    } else {
      const contactGroup2 = contactGroups2.value!.contact_groups.find((cg) => cg.contact_group === data.name)
      const contactValues = JSON.parse(JSON.stringify(contactGroup2?.contact_values))
      const countContacts = [...contactValues, ...mm]

      storeContactGroups.setContactGroups2({
        contact_groups: [
          ...newContactGroup2, {
            contact_group: contactGroup2!.contact_group,
            contact_values: countContacts
          }
        ]
      })

      storeContactGroups.setContactGroups([
        ...newContactGroups, {
          contact_group: contactGroup!.contact_group,
          contacts_number: countContacts.length,
          items: []
        }])

      storeContactGroups.setContact([...contacts.value, ...m])
    }
  })

  const renameContactGroupMutation = useMutation(async (data: { name: string; newName: string; }):Promise<void> => {
    const contactGroup = contactGroups.value.find((cg) => cg.contact_group === data.name)
    const newContactGroups = contactGroups.value.filter((cg) => cg.contact_group !== data.name)
    storeContactGroups.setContactGroups([
      ...newContactGroups, {
        contact_group: data.newName,
        contacts_number: contactGroup!.contacts_number,
        items: []
      }])

    const contactGroup2 = contactGroups2.value!.contact_groups.find((cg) => cg.contact_group === data.name)
    const newContactGroup2 = contactGroups2.value!.contact_groups.filter((cg) => cg.contact_group !== data.name)
    storeContactGroups.setContactGroups2({
      contact_groups: [
        ...newContactGroup2, {
          contact_group: data.newName,
          contact_values: contactGroup2!.contact_values
        }
      ]
    })
  })

  const removeContactGroupMutation = useMutation(async (data: { name: string; }):Promise<void> => {
    const newContactGroups = contactGroups.value.filter((cg) => cg.contact_group !== data.name)
    storeContactGroups.setContactGroups([...newContactGroups])

    const newContactGroup2 = contactGroups2.value!.contact_groups.filter((cg) => cg.contact_group !== data.name)
    storeContactGroups.setContactGroups2({
      contact_groups: [...newContactGroup2]
    })
  })

  const removeContactMutation = useMutation(async (data: { name: string; mailboxes: string[]; }):Promise<void> => {
    const o = contacts.value.filter((c) => !data.mailboxes.includes(c.id))

    const contactGroup = contactGroups.value.find((cg) => cg.contact_group === data.name)
    const newContactGroups = contactGroups.value.filter((cg) => cg.contact_group !== data.name)

    const newContactGroup2 = contactGroups2.value!.contact_groups.filter((cg) => cg.contact_group !== data.name)
    const cg = contactGroups2.value!.contact_groups.find((cg) => cg.contact_group === data.name)
    const cv = cg!.contact_values.filter((c) => !data.mailboxes.includes(c.id))
    const a = [...JSON.parse(JSON.stringify(newContactGroup2))]

    const countContacts = [...o]

    storeContactGroups.setContactGroups2({
      contact_groups: [
        ...a, {
          contact_group: contactGroup?.contact_group,
          contact_values: [...cv]
        }]
    })

    storeContactGroups.setContactGroups([
      ...newContactGroups, {
        contact_group: contactGroup!.contact_group,
        contacts_number: countContacts.length,
        items: []
      }])

    storeContactGroups.setContact(countContacts)
  })

  return {
    // properties
    contactGroups,
    contacts,

    // Method
    getContactGroups: getContactGroupsMutation,
    getContacts: getContactsMutation,
    AddContactGroup: AddContactGroupMutation,
    renameContactGroup: renameContactGroupMutation,
    removeContactGroup: removeContactGroupMutation,
    removeContact: removeContactMutation
  }
}

export default useContactGroups
